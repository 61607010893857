import {
  PaginatedPutAwayTaskSummaryDtos,
  PutAwayItemRequest,
  PutAwayTaskSummaryDto,
  SetDecantingRateRequest
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const putAwayTaskApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getPutawayTasks: build.query<
      PutAwayTaskSummaryDto[],
      { status?: string; variantId?: Guid }
    >({
      query: ({ status, variantId }) => ({
        url: `/put-away-tasks`,
        method: "GET",
        params: { status, variantId }
      }),
      providesTags: ["put away task"]
    }),

    getPutawayTasksWithPaging: build.query<
      PaginatedPutAwayTaskSummaryDtos,
      { status?: string; search?: string; limit?: number; offset?: number }
    >({
      query: ({ status, search, limit, offset }) => ({
        url: `/put-away-tasks/with-paging`,
        method: "GET",
        params: { status, search, limit, offset }
      }),
      providesTags: ["put away task"]
    }),

    patchDecantingRate: build.mutation<
      void,
      {
        putAwayTaskId: Guid;
        request: SetDecantingRateRequest;
      }
    >({
      query: ({ putAwayTaskId, request }) => ({
        url: `/put-away-tasks/${putAwayTaskId}/decanting-rate`,
        method: "PATCH",
        body: request
      }),
      invalidatesTags: ["put away task"]
    }),

    postPutItemAway: build.mutation<
      void,
      {
        putAwayTaskId: Guid;
        request: PutAwayItemRequest;
      }
    >({
      query: ({ putAwayTaskId, request }) => ({
        url: `/put-away-tasks/${putAwayTaskId}/put-item-away`,
        method: "POST",
        body: request
      }),
      invalidatesTags: ["put away task"]
    })
  }),
  overrideExisting: false
});
