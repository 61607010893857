import { Button, Toolbar, Typography } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";
import { useGetPutawayTasksWithPagingQuery } from "~/redux/warehouse/putAwayTasks.hooks";

import PrintablePutawayLabels from "./printablePutawayLabels";
import { clearSelectedPutawayTasks } from "./putaway.slice";

type Props = { limit: number };

export function PutawayToolbar({ limit }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const printComponentRef = useRef<HTMLDivElement>(null);

  const page = useAppSelector((state) => state.putaway.page);
  const searchText = useAppSelector((state) => state.putaway.search);
  const selectedPutawayTasks = useAppSelector(
    (state) => state.putaway.selectedPutawayTasks
  );

  const { data } = useGetPutawayTasksWithPagingQuery({
    status: "scheduled",
    limit,
    offset: (page - 1) * limit,
    ...(searchText && { search: searchText })
  });

  const putawayInventory = data?.putAwayTasks;

  const handlePrint = useReactToPrint({
    pageStyle: `
      @page {
        margin: 0
      }
    `,
    contentRef: printComponentRef
  });

  const putawayLabels =
    !!selectedPutawayTasks.length &&
    selectedPutawayTasks.map((taskId) => {
      const task = putawayInventory?.find(
        (inv) => inv.putAwayTaskId === taskId
      );
      return {
        putawayTaskId: taskId,
        sku: task?.product.sku || "",
        productName: task?.product.name || "",
        units: task?.quantity.units || "ea"
      };
    });

  const selectedPutawayTask =
    selectedPutawayTasks.length === 1 &&
    putawayInventory?.find(
      (task) => task.putAwayTaskId === selectedPutawayTasks[0]
    );

  return (
    <Toolbar>
      <UnselectButton onClick={() => dispatch(clearSelectedPutawayTasks())} />
      <NavBarButtonGroup>
        <Button color="secondary" onClick={() => handlePrint()}>
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("print labels")}
          </Typography>
        </Button>

        {selectedPutawayTask && (
          <Button
            color="secondary"
            id="clear-batch-id"
            onClick={() =>
              navigate(`/putaway/${selectedPutawayTask.product.variantId}`)
            }
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {t("view putaway")}
            </Typography>
          </Button>
        )}
      </NavBarButtonGroup>

      <div style={{ display: "none" }}>
        {putawayInventory && putawayLabels && (
          <PrintablePutawayLabels
            labels={putawayLabels}
            ref={printComponentRef}
          />
        )}
      </div>
    </Toolbar>
  );
}
