import BackHandIcon from "@mui/icons-material/BackHand";
import { Box, Checkbox, Collapse, Typography } from "@mui/material";
import { andonColors, ProgressButton, AutostoreTable } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "~/app/store";

import TwoColumnTable from "~/components/table/TwoColumnTable";

import {
  figureAndonColorFromStatus,
  extractReceipientId,
  extractDisplayName
} from "~/lib/helpers";

import { usePostDeactivateWorkstationMutation } from "~/redux/warehouse/workstation.hooks";

import Messenger, { Recipient } from "./Messenger";

import WorkstationSelectorModal from "./WorkstationSelectorModal";
import {
  setAdminSummaryIds,
  AndonWorkstation,
  selectAndonWorkstations,
  selectAndonGrids,
  selectAdminSummaryIds
} from "./andon.slice";

export default function AdminSummariesTab() {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<Guid[]>([]);
  const [messengerIsOpen, setMessengerIsOpen] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [checkedWorkstationIds, setCheckedWorkstationIds] = useState<string[]>(
    []
  );
  const andonWorkstations = useAppSelector(selectAndonWorkstations);
  const andonGrids = useAppSelector(selectAndonGrids);
  const adminSummaryIds = useAppSelector(selectAdminSummaryIds);

  const [workstationSelectorOpen, setWorkstationSelectorOpen] = useState(false);

  const [postDeactivateWorkstation] = usePostDeactivateWorkstationMutation();

  const dispatch = useDispatch();

  const activeWorkstations = Object.values(andonWorkstations).filter(
    (aws) =>
      adminSummaryIds.includes(aws.id) && aws.workstation.status === "Active"
  );

  const openMessageComponent = (
    event: React.MouseEvent | React.KeyboardEvent,
    newRecipients: Recipient[]
  ) => {
    event.stopPropagation();

    setRecipients(newRecipients);
    setMessengerIsOpen(true);
  };

  const handleCheckboxClick = (workstationId: string) => {
    if (checkedWorkstationIds.includes(workstationId)) {
      setCheckedWorkstationIds(
        checkedWorkstationIds.filter((id) => id !== workstationId)
      );
    } else {
      setCheckedWorkstationIds([...checkedWorkstationIds, workstationId]);
    }
  };

  const handleMessageUsers = () => {
    setRecipients(
      Object.values(andonWorkstations)
        .filter((aws) => checkedWorkstationIds.includes(aws.id))
        .map((aws) => ({
          workstationUserId: aws.workstation?.userId,
          recipientId: extractReceipientId(aws.workstation?.userId),
          displayName: extractDisplayName(aws.workstation?.userId)
        }))
    );
    setMessengerIsOpen(true);
  };

  const handleSelectAll = () => {
    const allWorkstationIds = activeWorkstations.map((aws) => aws.id);

    const allSelected =
      activeWorkstations.length === checkedWorkstationIds.length;

    if (!allSelected) {
      setCheckedWorkstationIds(allWorkstationIds);
    } else {
      setCheckedWorkstationIds([]);
    }
  };

  const handleDeactivateWorkstations = async () => {
    await Promise.all(
      checkedWorkstationIds.map((workstationId) =>
        postDeactivateWorkstation(workstationId)
      )
    );
  };

  return (
    <Box
      id="workstation-summaries-container"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {/* button box */}
      <Box
        sx={{
          height: "10%",
          marginLeft: "20px",
          marginRight: "20px",
          display: "flex",
          width: "100%",
          alignItems: "center"
        }}
      >
        <ProgressButton
          emphasis="high"
          responsive
          variant="contained"
          color="primary"
          onClick={() => {
            setWorkstationSelectorOpen(true);
          }}
          sx={{
            mb: 2
          }}
        >
          {t("select workstations")}
        </ProgressButton>

        <ProgressButton
          emphasis="high"
          responsive
          variant="contained"
          color="primary"
          disabled={!checkedWorkstationIds.length}
          style={{
            marginLeft: "10px",
            display: !checkedWorkstationIds.length ? "none" : undefined
          }}
          onClick={handleMessageUsers}
        >
          <Typography>{t("message users")}</Typography>
        </ProgressButton>

        <ProgressButton
          buttonSize="small"
          emphasis="high"
          responsive
          color="primary"
          disabled={!checkedWorkstationIds.length}
          style={{
            marginLeft: "10px",
            display: !checkedWorkstationIds.length ? "none" : undefined
          }}
          onClick={handleDeactivateWorkstations}
        >
          <Typography>{t("deactivate workstations")}</Typography>
        </ProgressButton>
      </Box>

      {/* summaries content */}
      <Box
        id="workstation-summaries-content"
        sx={{
          width: "100%",
          height: "90%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Box
          id="workstation-summaries-table-container"
          sx={{ height: "100%", width: "90%" }}
        >
          <AutostoreTable<AndonWorkstation>
            headerColNames={[
              <Box
                key="select-checkbox"
                data-key="select-checkbox"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Checkbox
                  aria-label="select all workstations"
                  onChange={() => handleSelectAll()}
                  sx={{ cursor: "pointer" }}
                  checked={
                    activeWorkstations.length === checkedWorkstationIds.length
                  }
                />
              </Box>,
              t("device id"),
              t("user"),
              t("autostore parent port"),
              t("ports")
            ]}
            widthOfCols={["10%", "20%", "30%", "15%", "25%"]}
            rowId={(ws: AndonWorkstation) => `${ws.id}`}
            renderColumns={[
              (aws: AndonWorkstation) => (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Checkbox
                    onChange={() => handleCheckboxClick(aws.workstation.id)}
                    sx={{ cursor: "pointer" }}
                    checked={checkedWorkstationIds.includes(aws.workstation.id)}
                  />
                </Box>
              ),

              (aws: AndonWorkstation) => (
                <Box
                  sx={{
                    backgroundColor: figureAndonColorFromStatus({
                      workstationActive: aws.workstation.status === "Active",
                      workstationStatus: aws.status,
                      workstationOpen: aws.isOpen,
                      gridSystemMode:
                        andonGrids[aws.workstation.autostoreGridId]
                          .systemMode || undefined,
                      handRaised: aws.handRaised
                    }),

                    color: aws.handRaised ? "black" : "white",
                    cursor: "pointer",
                    padding: "10px"
                  }}
                  onClick={() => {
                    const rowId = aws.id;
                    if (selectedRows.includes(rowId)) {
                      setSelectedRows(
                        selectedRows.filter((id) => id !== rowId)
                      );
                    } else {
                      setSelectedRows([...selectedRows, rowId]);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      const rowId = aws.id;
                      if (selectedRows.includes(rowId)) {
                        setSelectedRows(
                          selectedRows.filter((id) => id !== rowId)
                        );
                      } else {
                        setSelectedRows([...selectedRows, rowId]);
                      }
                    }
                  }}
                >
                  <Typography>{`${aws.workstation.deviceId}`}</Typography>
                </Box>
              ),
              (aws: AndonWorkstation) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    cursor: "pointer"
                  }}
                  onClick={(event) => {
                    openMessageComponent(event, [
                      {
                        workstationUserId: aws.workstation.userId,
                        recipientId: extractReceipientId(
                          aws.workstation.userId
                        ),
                        displayName: extractDisplayName(aws.workstation.userId)
                      }
                    ]);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      openMessageComponent(event, [
                        {
                          workstationUserId: aws.workstation.userId,
                          recipientId: extractReceipientId(
                            aws.workstation.userId
                          ),
                          displayName: extractDisplayName(
                            aws.workstation.userId
                          )
                        }
                      ]);
                    }
                  }}
                >
                  <Typography variant="body1">{`${
                    (aws.workstation.status === "Active" &&
                      extractDisplayName(aws.workstation.userId)) ||
                    ""
                  }
                `}</Typography>
                  {aws.handRaised && (
                    <Box
                      sx={{
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        marginLeft: "20px"
                      }}
                    >
                      <BackHandIcon
                        sx={{
                          width: "25px",
                          height: "25px",
                          color: andonColors.handRaisedYellow
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ),
              (aws: AndonWorkstation) => {
                let backgroundColor = "info.main";

                if (aws.parentPort) {
                  backgroundColor = figureAndonColorFromStatus({
                    workstationActive: aws.workstation.status === "Active",
                    workstationStatus: aws.status,
                    workstationOpen: aws.isOpen,
                    gridSystemMode:
                      andonGrids[aws.workstation.autostoreGridId].systemMode ||
                      undefined,
                    portStatus: aws.parentPort.status,
                    portOpen: aws.parentPort.isOpen,
                    handRaised: aws.handRaised
                  });
                }

                return (
                  <Box
                    key={aws.id}
                    sx={{
                      display: aws.parentPort ? "flex" : "none",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "100%"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px",
                        height: "30px",
                        width: "40px",
                        border: "1px solid gray",
                        backgroundColor
                      }}
                    >
                      <Typography>{`${
                        aws.parentPort?.portId || ""
                      }`}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "40px",
                        height: "30px",
                        padding: "5px",
                        border: aws.parentPort ? "1px dotted gray" : undefined,
                        backgroundColor: aws.parentPort?.hasBins.length
                          ? "black"
                          : undefined,
                        color: "white"
                      }}
                    >
                      <Typography>
                        {`${
                          aws.parentPort?.hasBins.length
                            ? aws.parentPort.hasBins.join(" • ")
                            : ""
                        }`}
                      </Typography>
                    </Box>
                  </Box>
                );
              },
              (aws: AndonWorkstation) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {aws.ports.map((port) => {
                    const backgroundColor = figureAndonColorFromStatus({
                      portOpen: port.isOpen,
                      portStatus: port.status,
                      workstationActive: aws.workstation.status === "Active",
                      workstationStatus: aws.status,
                      gridSystemMode:
                        andonGrids[aws.workstation.autostoreGridId]
                          .systemMode || undefined,
                      handRaised: aws.handRaised
                    });

                    return (
                      <Box
                        key={port.portId}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "50px",
                          marginRight: "10px"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                            height: "30px",
                            width: "40px",
                            border: "1px solid gray",
                            backgroundColor
                          }}
                        >
                          <Typography>{`${port.portId || ""}`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            width: "40px",
                            height: "30px",
                            padding: "5px",
                            border: !aws.parentPort
                              ? "1px dotted gray"
                              : undefined,
                            backgroundColor: port.hasBins.length
                              ? "black"
                              : undefined,
                            color: "white"
                          }}
                        >
                          <Typography>
                            {`${port.hasBins ? port.hasBins.join(" • ") : ""}`}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )
            ]}
            headerVariant="h5"
            rowData={activeWorkstations}
            selectedRows={selectedRows}
            renderSelectedRowExpand={(aws: AndonWorkstation) => (
              <Collapse in timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    width: "80%",
                    backgroundColor: "primary.main",
                    color: "white",
                    padding: "10px"
                  }}
                >
                  <TwoColumnTable
                    tableData={[
                      {
                        left: "roles",
                        right: aws.workstation.roles.join(", ")
                      },
                      {
                        left: "orientation",
                        right: aws.workstation.orientation
                      },
                      { left: "id", right: aws.id },
                      {
                        left: "most recent mode",
                        right:
                          aws.workstation.mode !== "Undefined"
                            ? aws.workstation.mode
                            : ""
                      }
                    ]}
                    firstColumnWidth="20%"
                  />

                  {/* batch threads ? average pick time?  */}
                </Box>
              </Collapse>
            )}
            sort={(a: AndonWorkstation, b: AndonWorkstation) =>
              a.workstation.autostoreGridName.localeCompare(
                b.workstation.autostoreGridName
              ) || a.workstation.deviceId.localeCompare(b.workstation.deviceId)
            }
            loading={false}
          />
        </Box>
      </Box>
      {messengerIsOpen && recipients.length && (
        <Messenger
          recipients={recipients}
          onCloseCb={() => {
            setMessengerIsOpen(false);
            setRecipients([]);
          }}
          isAll={activeWorkstations.length === recipients.length}
        />
      )}
      {/* modals */}
      <WorkstationSelectorModal
        isOpen={workstationSelectorOpen}
        selectedWorkstationIds={adminSummaryIds}
        workstationSelectorCb={(newIds) => {
          dispatch(setAdminSummaryIds(newIds));
        }}
        setModalOpen={(isOpen) => setWorkstationSelectorOpen(isOpen)}
        footer={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Box sx={{ width: "150px" }}></Box>
          </Box>
        }
      />
    </Box>
  );
}
