import { Button, Toolbar, Typography } from "@mui/material";

import { useToast } from "@qubit/autoparts";
import moment from "moment/moment";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { SelectorModal } from "~/components/autostore/modals/SelectorModal";
import InventoryAdjustDialog, {
  InventoryToModify
} from "~/features/inventoryAdjustDialog/InventoryAdjustDialog";
import { NavBarButtonGroup, UnselectButton } from "~/features/navbar/Navbar";

import {
  generateLocationNameFromBin,
  getHoldTypeOptions,
  getInventoryDateObj,
  HoldType
} from "~/lib/helpers";
import {
  fetchCycleCountAndBins,
  fetchCycleCountBins,
  postBinCount
} from "~/redux/actions/cycleCounts";
import {
  getInventoryReport,
  placeInventoryHold
} from "~/redux/actions/inventory";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";

import { selectCycleCountBinId } from "./cycleCounts.slice";

export function CycleCountToolbar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { errorToast, successToast } = useToast();

  const {
    ap_excludeRecalledHold: excludeRecalledHold,
    inv_inventoryDateLabel
  } = useAppSelector(selectClientConfig);
  const cycleCount = useAppSelector((state) => state.cycleCounts.cycleCount);
  const selectedCycleCountBinId = useAppSelector(
    (state) => state.cycleCountsSlice.selectedCycleCountBinId
  );
  const selectedInventory = useAppSelector(
    (state) => state.cycleCountsSlice.selectedInventory
  );
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const thisWorkstationId = useAppSelector(selectWorkstationId);
  const [isAdjustPanelOpen, setAdjustPanelOpen] = useState(false);
  const [isBinHoldModalOpen, setIsBinHoldModalOpen] = useState(false);

  const handleVerify = useCallback(async () => {
    if (
      cycleCount &&
      selectedInventory &&
      selectedInventory.count &&
      selectedCycleCountBinId
    ) {
      await dispatch(
        postBinCount({
          verifiedCount: selectedInventory.count,
          binId: selectedCycleCountBinId,
          inventoryId: selectedInventory.inventoryId,
          cycleCountId: cycleCount?.cycleCountId
        })
      );

      if (selectedVariant) {
        await dispatch(getInventoryReport(selectedVariant.variantId));
      }

      successToast("Verified", {
        description: `${selectedVariant?.variantName ? selectedVariant?.variantName + " " : ""}${
          selectedInventory.count.value
        } ${selectedInventory.count.units}`
      });
    } else {
      errorToast("No inventory selected");
    }
  }, [
    cycleCount,
    dispatch,
    errorToast,
    selectedCycleCountBinId,
    selectedInventory,
    selectedVariant,
    successToast
  ]);

  let selectedLocationName = "";
  if (selectedInventory) {
    selectedLocationName =
      selectedInventory.bin.binType === "autostore"
        ? "Autostore"
        : generateLocationNameFromBin(selectedInventory.bin);
  }

  const selectedInventoryToModify: InventoryToModify = selectedInventory
    ? {
        binType: selectedInventory.bin.binType,
        binId: selectedInventory.bin.binId,
        inventoryId: selectedInventory.inventoryId,
        locationName: selectedLocationName,
        count: selectedInventory.count,
        committedCount: selectedInventory.committedCount,
        ...getInventoryDateObj(
          inv_inventoryDateLabel,
          moment(selectedInventory?.expiration) ||
            moment(selectedInventory?.manufactureDate)
        ),
        lotNumber: selectedInventory.lotNumber,
        variantId: selectedInventory.variantId
      }
    : null;

  return (
    <Toolbar>
      <UnselectButton
        onClick={() => {
          dispatch(selectCycleCountBinId(null));
        }}
      />
      <NavBarButtonGroup>
        <Button
          color="secondary"
          onClick={handleVerify}
          style={{
            width: 175
          }}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("verify")}
          </Typography>
        </Button>

        <Button
          color="secondary"
          onClick={() => setAdjustPanelOpen(true)}
          style={{
            width: 175
          }}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("adjust")}
          </Typography>
        </Button>
        <Button
          color="secondary"
          onClick={() => setIsBinHoldModalOpen(true)}
          style={{
            width: 175
          }}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("nav.link.inventory holds")}
          </Typography>
        </Button>
      </NavBarButtonGroup>
      <InventoryAdjustDialog
        open={isAdjustPanelOpen}
        onClose={() => {
          setAdjustPanelOpen(false);
          dispatch(selectCycleCountBinId(null));
        }}
        invToModify={selectedInventoryToModify}
        selectedVariant={selectedVariant || null}
        refreshCb={async () => {
          if (cycleCount) {
            await dispatch(fetchCycleCountBins([cycleCount.variant.variantId]));
          }
          setAdjustPanelOpen(false);
        }}
        canceledReason=""
      />
      <SelectorModal
        confirmCb={async (holdBin: HoldType) => {
          const { reasonCode } = holdBin;
          await dispatch(
            placeInventoryHold(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              selectedInventory!.inventoryId,
              reasonCode,
              thisWorkstationId === undefined ? null : thisWorkstationId
            )
          );

          setIsBinHoldModalOpen(false);
          if (selectedVariant && cycleCount) {
            await dispatch(fetchCycleCountAndBins(cycleCount.cycleCountId));
          }
        }}
        open={isBinHoldModalOpen}
        closeModalCallback={() => setIsBinHoldModalOpen(false)}
        maxWidth="sm"
        options={getHoldTypeOptions(excludeRecalledHold)}
        confirmButtonText={t("set hold")}
        currentHold=""
      />
    </Toolbar>
  );
}
