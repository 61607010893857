import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SxProps,
  CardActionArea
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardMediaWithFallback, useToast } from "@qubit/autoparts";
import { QRCodeCanvas } from "qrcode.react";
import { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ScanningVerification } from "~/api/scanningVerifyMethod";
import { useAppDispatch, useAppSelector } from "~/app/store";
import TextTrimmer from "~/components/autostore/TextTrimmer";
import { setPickedQuantityModalStatus } from "~/components/autostore/modals/confirmPickQuantityModal.slice";
import envConstants from "~/config/envConstants";
import useFlag from "~/config/flags";

import { displayPickData, matchExactUpcsOrSkus } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { setIsPickQuantityConfirmed } from "~/redux/actions";
import {
  selectBinIsPresent,
  selectIsPickQuantityConfirmed,
  selectPickingState
} from "~/redux/selectors/autostoreSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  selectSelectedPortId,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";
import { useVerifyPickMutation } from "~/redux/warehouse/pick.hooks";
import { AutostorePickingState } from "~/types/api";

import { BarcodeInput } from "./BarcodeInput";

const PaddedGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const ProductName = styled(Typography)(() => ({
  fontSize: "1.4em"
}));

const trimmedSkuStyle: SxProps = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  cursor: "pointer",
  maxHeight: "2rem",
  transition: "max-height 1s ease"
};
const expandedSkuStyle: SxProps = {
  wordBreak: "break-all",
  cursor: "pointer",
  maxHeight: "10rem",
  transition: "max-height 1s ease"
};

const ProductInfoTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary
}));

function UniversalProductCard(props: {
  productName: string;
  weight?: string;
  sku: string;
  upc: string;
  imageFileName: string;
  /** Renders quantity "on hand" below image */
  quantity?: number;
  /** Renders quantity inline with SKU and UPC */
  quantityInline?: string;
  style?: Record<string, unknown>;
  cardHeight?: string;
  completionPercentage?: number;
  showQRCode?: boolean;
  confirmPickCallback?: () => void;
  isCycleCountBlind?: boolean;
  allUpcs?: string[];
  brandName?: string;
  cycleCountFrequency?: number | null;
  disableGutters?: boolean;
  /** Optionally render a section under the image. Used for Putaway quantity and date fields */
  bottomSection?: ReactNode;
  hideProductCount?: boolean;
  displayEnterProductUpcButton?: boolean;
}) {
  const { t } = useTranslation();

  const {
    productName,
    weight,
    sku,
    upc,
    imageFileName,
    quantity,
    quantityInline,
    cardHeight,
    completionPercentage,
    showQRCode,
    confirmPickCallback,
    isCycleCountBlind,
    allUpcs,
    brandName,
    cycleCountFrequency,
    disableGutters,
    bottomSection,
    hideProductCount,
    displayEnterProductUpcButton
  } = props;

  // local state
  const [isProductNameExpanded, setIsProductNameExpanded] =
    useState<boolean>(false);

  const [isSkuCollapsed, setIsSkuCollapsed] = useState(false);
  const upcFieldName = allUpcs && allUpcs?.length === 1 ? "UPC" : "UPCS";
  const showCycleCountFrequencyCardDetails = useFlag().cycleCountFrequency;
  const dispatch = useAppDispatch();
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const pickingState = useAppSelector(selectPickingState);
  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const sitePortId = useAppSelector(selectSelectedPortId);
  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);
  const binIsPresent = useAppSelector(selectBinIsPresent);
  const simulateUpcScanFeatureFlag = useFlag().simulateUpcScan;
  const { errorToast } = useToast();
  const [verifyPick] = useVerifyPickMutation();

  const isSimulateUpcScanFeatureFlagEnabled =
    !!fulfillmentCenter &&
    fulfillmentCenter.pickQuantityConfirmationEnabled &&
    simulateUpcScanFeatureFlag;

  const isBarcodeScanEnabled =
    fulfillmentCenter?.pickQuantityConfirmationEnabled &&
    displayEnterProductUpcButton;

  const isBinReady =
    isSimulateUpcScanFeatureFlagEnabled &&
    binIsPresent &&
    !isPickQuantityConfirmed;

  let frequencyDetails = t("n/a");
  if (cycleCountFrequency) {
    frequencyDetails = `${t("every")} ${String(cycleCountFrequency)} ${t(
      "days"
    )}`;
    if (cycleCountFrequency === 1) frequencyDetails = t("every day");
  }

  const matchEnteredUpcOrSku = useCallback(
    (
      pickingState: AutostorePickingState | null,
      upcOrSku?: string,
      callback?: () => Promise<void> | void
    ) => {
      if (!pickingState) return;
      const pickId =
        pickingState.selectedPickId || pickingState.currentPicks[0]?.pickId;
      const currentPick = pickingState.currentPicks.find(
        (currentPick) => currentPick.pickId === pickId
      );
      if (!currentPick) return;
      const finalUpcOrSku = upcOrSku || currentPick.upc || currentPick.sku;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      verifyPick({
        upcOrSku: finalUpcOrSku,
        pickId,
        verifyMethod: ScanningVerification.INPUT,
        workstationId: selectedWorkstation?.id
      })
        .unwrap()
        .catch((err) => errorToast(getMessageFromRtkError(err)));
      const allUpcsOrSkus = [...currentPick.allUpcs, currentPick.sku];
      const isScannedUpcCorrect = matchExactUpcsOrSkus(
        allUpcsOrSkus,
        finalUpcOrSku
      );
      if (!isScannedUpcCorrect) return;

      // When the pick quantity is 1, we don't want to show
      // The pick quantity confirmation modal
      if (currentPick.quantity?.value === 1) {
        // next pr
        dispatch(setIsPickQuantityConfirmed(true));
        dispatch(
          setPickedQuantityModalStatus({
            isShown: false,
            pickedQuantity: null,
            selectedBinId: null
          })
        );

        // The user should be able to complete the pick from PTL also
        if (sitePortId)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          displayPickData({
            portId: sitePortId,
            pickId,
            batchId: currentPick.batchId || pickingState.batchId,
            gridId: selectedWorkstation?.autostoreGridId
          });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        if (callback) callback();
        return;
      }
      // When the pick quantity is > 1, display the pick quantity confirmation modal
      dispatch(
        setPickedQuantityModalStatus({
          isShown: true,
          pickedQuantity: currentPick.quantity?.value,
          selectedBinId:
            currentPick.pickBin?.autostoreBin?.autostoreBinId || null
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      if (callback) callback();
    },
    [
      dispatch,
      errorToast,
      verifyPick,
      selectedWorkstation?.autostoreGridId,
      selectedWorkstation?.id,
      sitePortId
    ]
  );

  const handleProductImageClick = () => {
    if (confirmPickCallback) {
      confirmPickCallback();
      return;
    }
    if (isBinReady) {
      matchEnteredUpcOrSku(pickingState);
    }
  };

  return (
    <Container disableGutters={disableGutters}>
      <Card
        style={{
          height: cardHeight || "inherit",
          position: "relative",
          padding: "16px"
        }}
      >
        <Grid
          container
          id="productInfo"
          sx={{ paddingBottom: "16px" }}
          justifyContent="space-between"
        >
          <Grid item>
            <TextTrimmer
              text={productName}
              minLength={80}
              isExpanded={isProductNameExpanded}
              setIsExpanded={setIsProductNameExpanded}
            />
          </Grid>
          {isBarcodeScanEnabled && (
            <Grid item>
              <BarcodeInput matchEnteredUpcOrSku={matchEnteredUpcOrSku} />
            </Grid>
          )}
        </Grid>
        {quantityInline && (
          <Box>
            <ProductInfoTypography variant="overline">
              Quantity
            </ProductInfoTypography>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              style={{ fontWeight: 400 }}
            >
              {quantityInline}
            </Typography>
          </Box>
        )}
        <Box id="sku" mx={-2} bgcolor="gray.light" px={2} pt={2}>
          <Typography
            variant="h6"
            component="h6"
            fontSize="1rem"
            sx={isSkuCollapsed ? trimmedSkuStyle : expandedSkuStyle}
            onClick={() => setIsSkuCollapsed(!isSkuCollapsed)}
            style={{ fontWeight: 400 }}
          >
            {`SKU: ${sku}`}
          </Typography>
        </Box>
        {allUpcs?.length && (
          <Box id="upcs" mx={-2} bgcolor="gray.light" py={2}>
            {allUpcs?.length && allUpcs.length > 1 ? (
              <Accordion
                square
                disableGutters
                sx={{ boxShadow: "none", bgcolor: "gray.light" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="upcs-content"
                  id="upcs-content"
                  style={{
                    minHeight: "20px",
                    height: "20px"
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h6"
                    fontSize="1rem"
                    style={{ padding: 0, margin: 0, fontWeight: 400 }}
                  >
                    {upcFieldName + ": " + allUpcs[0]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: 0,
                    margin: 0,
                    pt: 0.5,
                    bgcolor: "gray.light",
                    paddingLeft: 8
                  }}
                >
                  {allUpcs.map((upcRow: string, i: number) => {
                    if (i > 0)
                      return (
                        <Typography
                          key={i}
                          variant="h6"
                          component="h6"
                          fontSize="1rem"
                          style={{ fontWeight: 400 }}
                        >
                          {upcRow}
                        </Typography>
                      );

                    return false;
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Typography
                variant="h6"
                component="h6"
                fontSize="1rem"
                sx={{ fontWeight: 400, px: 2 }}
              >
                {upcFieldName + ": " + allUpcs[0]}
              </Typography>
            )}
          </Box>
        )}
        {!hideProductCount && (
          <div id="uom">
            <ProductInfoTypography variant="overline">
              Count
            </ProductInfoTypography>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              sx={{ fontWeight: 400 }}
            >
              {isCycleCountBlind ? "N/A" : weight}
            </Typography>
          </div>
        )}
        {brandName && (
          <div id="brandName">
            <ProductInfoTypography variant="overline">
              {t("brand")}
            </ProductInfoTypography>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              style={{ fontWeight: 400 }}
            >
              {brandName || "N/A"}
            </Typography>
          </div>
        )}

        {showCycleCountFrequencyCardDetails && cycleCountFrequency && (
          <div id="cycleCountFrequency">
            <ProductInfoTypography variant="overline">
              {t("cycle count frequency")}
            </ProductInfoTypography>
            <Grid>
              <Typography
                gutterBottom
                variant="h6"
                component="h6"
                style={{ fontWeight: 400 }}
              >
                {frequencyDetails}
              </Typography>
            </Grid>
          </div>
        )}
        <Grid container id="image" data-testid="image-container">
          {!!completionPercentage && (
            <Grid
              item
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                position: "absolute",
                zIndex: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.5,
                  top: 0,
                  left: 0,
                  width: `${completionPercentage}%`,
                  height: "100%",
                  backgroundColor:
                    completionPercentage === 100
                      ? "success.main"
                      : "warning.main"
                }}
              />
              {completionPercentage === 100 && (
                <Box
                  style={{
                    height: 100,
                    width: 100,
                    position: "absolute",
                    zIndex: 101
                  }}
                >
                  <CheckCircleIcon
                    style={{ fill: "white", width: 100, height: 100 }}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={2} />
          <PaddedGrid item xs={8}>
            <CardActionArea
              aria-label="product"
              onClick={handleProductImageClick}
              disabled={!confirmPickCallback && !isBinReady}
            >
              <CardMediaWithFallback
                image={imageFileName}
                title="product"
                sx={{
                  maxHeight: isProductNameExpanded ? "100px" : "200px",
                  transition: "max-height 0.3s ease"
                }}
              />
            </CardActionArea>
          </PaddedGrid>
          <Grid item xs={2}>
            {showQRCode && envConstants.SHOW_SCANNABLE_BAR_CODES === "true" && (
              <Box position="absolute" right={0}>
                <QRCodeCanvas value={upc} size={100} includeMargin={false} />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid>
          {quantity && (
            <Grid container id="quantity">
              <PaddedGrid item xs={9} />
              <PaddedGrid item xs={3}>
                <ProductName
                  style={
                    cardHeight
                      ? { position: "absolute", bottom: "0.625em" }
                      : {}
                  }
                >
                  <span
                    style={{ textDecoration: "underline" }}
                  >{`${quantity}`}</span>
                  {` ${t("on hand")}`}
                </ProductName>
              </PaddedGrid>
            </Grid>
          )}
          {bottomSection}
        </Grid>
      </Card>
    </Container>
  );
}

export default UniversalProductCard;

export function PickInfoIsLoading(props: {
  height?: string | number;
  innerHeight?: string | number;
  marginTop?: string;
  disableGutters?: boolean;
}) {
  const { height, marginTop, innerHeight, disableGutters } = props;
  return (
    <Container disableGutters={disableGutters}>
      <Card
        style={{
          marginTop,
          paddingTop: 10,
          overflow: "visible",
          height
        }}
        elevation={2}
      >
        <Grid container>
          <PaddedGrid item xs={9}>
            <ProductName>
              <Skeleton height={40} variant="rectangular" animation={false} />
            </ProductName>
          </PaddedGrid>
        </Grid>
        <Grid container>
          <PaddedGrid item xs={9}>
            <ProductName>
              <Skeleton height={40} variant="rectangular" animation={false} />
            </ProductName>
          </PaddedGrid>
          <PaddedGrid item xs={3}>
            <ProductName>
              <Skeleton height={40} variant="rectangular" animation={false} />
            </ProductName>
          </PaddedGrid>
        </Grid>
        <Grid container style={height ? { height } : {}}>
          <PaddedGrid item xs={12}>
            <Skeleton
              variant="rectangular"
              animation={false}
              height={innerHeight || 265}
            />
          </PaddedGrid>
        </Grid>
      </Card>
    </Container>
  );
}
