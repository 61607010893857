import { Grid, Typography, Pagination, Stack } from "@mui/material";
import {
  AutostoreTable,
  ConfirmationModal,
  formatUtcDate
} from "@qubit/autoparts";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { SelectorModal } from "~/components/autostore/modals/SelectorModal";
import UniversalProductCard, {
  PickInfoIsLoading
} from "~/components/productCard/UniversalProductCard";
import { AutostoreBin } from "~/features/autostoreBin";
import InventoryAdjustDialog, {
  InventoryToModify
} from "~/features/inventoryAdjustDialog/InventoryAdjustDialog";

import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import {
  generateLocationNameFromBin,
  getVariantDisplayNameByDtoFe,
  getDisplayNameByDto,
  inventoryDateLabel,
  getInventoryDateObj,
  checkIsExpiration,
  getHoldTypeOptions,
  formatHoldText,
  HoldType
} from "~/lib/helpers";
import { useGridV2Subscription } from "~/lib/signalr";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import {
  fetchPortStatus,
  openPort,
  closePort,
  createBinRequest,
  getNextBinWithConfig,
  closeBin,
  binAtPortEvent,
  binNotAtPort,
  fetchBinLogPublisherState,
  setNoMoreBins,
  closeWorkstation
} from "~/redux/actions/autostore";
import {
  fetchCycleCountsUncountedBins,
  postBinCount,
  clearCycleCountsState,
  completeCycleCounts,
  modifyUncountedBin,
  modifyShapedCycleCountInfo,
  clearCycleCountsMessage
} from "~/redux/actions/cycleCounts";
import { placeInventoryHold } from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";

import {
  selectedInventoryInBin,
  selectInventoryAtPort,
  selectUncountedBinAtPort
} from "~/redux/selectors/autostoreCycleCountsSelectors";
import { useGetIncompleteCycleCountsQuery } from "~/redux/warehouse/cycleCount.hooks";
import {
  IncompleteCycleCountDto,
  AutostoreBinConfigurationDto,
  UncountedBinDto,
  AutostoreEvent
} from "~/types/api";

import { AutostoreCycleCountsToolbar } from "./AutostoreCycleCountsToolbar";

import {
  clearSelectedBinRow,
  clearSelectedRows,
  selectBinRow,
  selectRows,
  setBinPresent,
  setIsAdjustPanelOpen,
  setIsBinHoldModalOpen,
  setPage,
  setPageState,
  setPortOpened,
  setPortPollingActive
} from "./autostoreCycleCounts.slice";

const mapStateToProps = (state: StoreState) => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  selectedPortId: state.workstations.sitePortId,
  portState: state.autostore.portState,
  inv_inventoryDateLabel: state.site.clientConfig.inv_inventoryDateLabel,
  loadingCycleCountBins: state.cycleCounts.loadingCycleCountBins,
  cycleCountsUncountedBins: state.cycleCounts.cycleCountsUncountedBins,
  shapedCycleCountInfos: state.cycleCounts.shapedCycleCountInfos,
  nextBinInventoryResponse: state.autostore.nextBinInventoryResponse,
  nextBinConfiguration: state.autostore.nextBinConfiguration,
  binAtPort: state.autostore.binAtPort,
  noMoreBins: state.autostore.noMoreBins,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  siteAllPortIds: state.workstations.siteAllPortIds,
  thisWorkstationId: state.workstations.siteWorkstation?.id,
  excludeRecalledHold: state.site.clientConfig.ap_excludeRecalledHold
});

const connector = connect(mapStateToProps, {
  fetchPortStatus,
  openPort,
  closePort,
  createBinRequest,
  fetchCycleCountsUncountedBins,
  getNextBinWithConfig,
  closeBin,
  clearCycleCountsMessage,
  modifyUncountedBin,
  modifyShapedCycleCountInfo,
  postBinCount,
  clearCycleCountsState,
  completeCycleCounts,
  binAtPortEvent,
  binNotAtPort,
  fetchBinLogPublisherState,
  setNoMoreBins,
  placeInventoryHold,
  closeWorkstation
});

type AutostoreCycleCountsInheritedProps = { viewTitle: ViewNameTranslation };
type PropsFromRedux = ConnectedProps<typeof connector>;
type AutostoreCycleCountsProps = PropsFromRedux &
  AutostoreCycleCountsInheritedProps;

function AutostoreCycleCounts(props: AutostoreCycleCountsProps) {
  const {
    selectedAutostoreGridId,
    selectedPortId,
    portState,
    cycleCountsUncountedBins,
    shapedCycleCountInfos,
    nextBinInventoryResponse,
    inv_inventoryDateLabel,
    nextBinConfiguration,
    binAtPort,
    noMoreBins,
    usersFulfillmentCenter,
    siteAllPortIds,
    thisWorkstationId,
    viewTitle,
    excludeRecalledHold,
    clearCycleCountsState,
    setNoMoreBins,
    closeWorkstation
  } = props;

  const { t } = useTranslation();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const dispatch = useAppDispatch();
  const { setToolbar } = useNavbar({ viewTitle });

  const isAdjustPanelOpen = useAppSelector(
    (state) => state.autostoreCycleCounts.isAdjustPanelOpen
  );
  const isBinHoldModalOpen = useAppSelector(
    (state) => state.autostoreCycleCounts.isBinHoldModalOpen
  );
  const pageState = useAppSelector(
    (state) => state.autostoreCycleCounts.pageState
  );
  const portOpened = useAppSelector(
    (state) => state.autostoreCycleCounts.portOpened
  );
  const portPollingActive = useAppSelector(
    (state) => state.autostoreCycleCounts.portPollingActive
  );
  const selectedBinRow = useAppSelector(
    (state) => state.autostoreCycleCounts.selectedBinRow
  );
  const selectedInventory = useAppSelector(selectedInventoryInBin);
  const selectedRows = useAppSelector(
    (state) => state.autostoreCycleCounts.selectedRows
  );
  const limit = useAppSelector((state) => state.autostoreCycleCounts.limit);
  const offset = useAppSelector((state) => state.autostoreCycleCounts.offset);
  const page = useAppSelector((state) => state.autostoreCycleCounts.page);

  useDevCheats({ isPortPolling: portPollingActive, showAutostoreStatus: true });

  // Local State
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  // bin configuration info
  const [autostoreBinConfiguration, setAutostoreBinConfiguration] =
    useState<AutostoreBinConfigurationDto | null>(null);
  const [compartment, setCompartment] = useState<number | null>(null);

  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);

  // two methods to see what bin is coming next:
  // 1) match using next bin response and uncounted bins response
  // 2) while polling, compare the portState response bin
  // and match that to an uncounted bin

  // use method 1:

  // next bin response is an array, potentially with more than one inventory
  // find the inventory id that matches an uncounted bin's inventory id
  const matchingNextBinInventory = useAppSelector(selectInventoryAtPort);

  // above we get the inventory from nextResponse endpoint and uncounted bins endpoint
  // here we use the same sources to get the uncountedBin
  const matchingUncountedBin = useAppSelector(selectUncountedBinAtPort);

  // when we know what bin is coming, set the cycle count status
  useEffect(() => {
    if (!matchingUncountedBin) return;

    props.modifyShapedCycleCountInfo({
      cycleCountId: matchingUncountedBin.cycleCountId,
      newCycleCountStatus: "active"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingUncountedBin]);

  // prepare inventory to modify for adjust inventory modal
  let selectedLocationName = "";

  if (matchingNextBinInventory) {
    selectedLocationName = generateLocationNameFromBin(
      matchingNextBinInventory.bin
    );
  }

  const invToModify: InventoryToModify = matchingNextBinInventory
    ? {
        binType: matchingNextBinInventory.bin.binType,
        binId: matchingNextBinInventory.bin.binId,
        inventoryId: matchingNextBinInventory.inventoryId,
        locationName: `Bin: ${selectedLocationName}`,
        count:
          matchingUncountedBin?.expectedCount || matchingNextBinInventory.count,
        committedCount: matchingNextBinInventory.committedCount,
        ...getInventoryDateObj(
          inv_inventoryDateLabel,
          moment(matchingNextBinInventory?.expiration) ||
            moment(matchingNextBinInventory?.manufactureDate)
        ),
        lotNumber: matchingNextBinInventory.lotNumber,
        variantId: matchingNextBinInventory.variantId
      }
    : null;

  const [allCycleCountsComplete, setAllCycleCountsComplete] =
    useState<boolean>(false);

  const {
    incompleteCycleCounts,
    incompleteCycleCountsCount,
    loadingIncompleteCycleCounts,
    isUninitialized,
    refetch
  } = useGetIncompleteCycleCountsQuery(
    { offset, limit, autostoreGridId: selectedAutostoreGridId },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isFetching, isUninitialized }) => ({
        incompleteCycleCounts: data?.items ?? [],
        incompleteCycleCountsCount: data?.totalCount,
        loadingIncompleteCycleCounts: isFetching,
        isUninitialized
      })
    }
  );

  const totalPageCount = incompleteCycleCountsCount
    ? Math.ceil(incompleteCycleCountsCount / limit)
    : 0;

  // fetch initial cycle counts
  useEffect(() => {
    if (selectedAutostoreGridId) {
      void dispatch(closePort());
      dispatch(setPortOpened(false));
    }
  }, [selectedAutostoreGridId, dispatch]);

  // pagination
  useEffect(() => {
    void dispatch(closePort());
    dispatch(setPortOpened(false));
  }, [limit, page, dispatch]);

  // reset page logic
  const resetPage = useCallback(() => {
    dispatch(clearSelectedRows());
    clearCycleCountsState();
    dispatch(setPortPollingActive(false));
    dispatch(setBinPresent(false));
    dispatch(setIsAdjustPanelOpen(false));
    setIsConfirmModalOpen(false);
    setNoMoreBins(false);
    dispatch(setPage(1));
    dispatch(setPageState("initial"));
    if (selectedAutostoreGridId && selectedPortId) {
      void closeWorkstation();
      dispatch(setPortOpened(false));
      if (!isUninitialized) void refetch();
    }
  }, [
    dispatch,
    clearCycleCountsState,
    setNoMoreBins,
    selectedAutostoreGridId,
    selectedPortId,
    closeWorkstation,
    isUninitialized,
    refetch
  ]);

  // component did unmount
  useEffect(
    () => () => {
      resetPage();
      if (shouldListenToGridEvents) {
        props.binNotAtPort();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // fires every time the page state changes
  // monitors when the bin has arrived
  useEffect(() => {
    if (pageState === "bin arrived") {
      dispatch(setPageState("bin here"));
      dispatch(setBinPresent(true));
    }
  }, [dispatch, pageState]);

  // portState polling
  usePromiseInterval(
    async () => {
      if (!selectedAutostoreGridId || !selectedPortId) return;

      await props.fetchPortStatus();
    },
    500,
    portPollingActive && !shouldListenToGridEvents
  );

  // Stop polling when a bin arrives
  useEffect(() => {
    // fires the page state "bin arrived" once when conditions are true
    if (
      portState &&
      portState.isReady &&
      portState.selectedBin &&
      !shouldListenToGridEvents
    ) {
      if (pageState !== "bin here" && pageState !== "initial") {
        dispatch(setPageState("bin arrived"));
      }

      dispatch(setPortPollingActive(false));

      // commented out the page runs on a loop since portState gets updated, then binAtPort updates
      // props.binNotAtPort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portState]);

  useEffect(() => {
    if (selectedRows.length) {
      setToolbar(<AutostoreCycleCountsToolbar resetPage={resetPage} />);
    } else {
      setToolbar(undefined);
    }
  }, [resetPage, selectedRows.length, setToolbar]);

  // POLL FOR BIN AT PORT INFORMATION IF EVENT ISN'T RECEIVED LONGER THAN 7 SECONDS
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > 7) {
        await props.fetchPortStatus().then((portStatus) => {
          if (portStatus && portStatus.isReady && selectedAutostoreGridId) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            props
              .fetchBinLogPublisherState(
                selectedAutostoreGridId,
                portStatus.selectedBin
              )
              .then((binStateResponse) => {
                if (
                  binStateResponse &&
                  binStateResponse.binState.binMode === "O" &&
                  binStateResponse.binState.portId === selectedPortId
                ) {
                  props.binAtPortEvent();
                  dispatch(setPageState("bin arrived"));
                  dispatch(setBinPresent(true));
                }
              });
          }
        });
      }
    },
    1000,
    !binAtPort && shouldListenToGridEvents && portOpened
  );

  useEffect(() => {
    if (binAtPort && binAtPortSeconds > 0) {
      setBinAtPortSeconds(0);
    }
    // autoselect the first row once the bin arrives
    if (binAtPort && !selectedInventory && matchingUncountedBin) {
      dispatch(selectBinRow(matchingUncountedBin.inventoryId));
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.fetchPortStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binAtPort]);

  // after the user clicks get bins,
  // then after uncounted bins are fetched, create a task group
  useEffect(() => {
    if (
      !cycleCountsUncountedBins.length ||
      !selectedAutostoreGridId ||
      !selectedPortId ||
      // now that we edit cycleCountsUncountedBins, we can't run this every time it changes
      pageState === "bin arrived"
    )
      return;
    dispatch(
      setPageState(
        "create task group with autostore bins from cycleCountsUncountedBins"
      )
    );
    const categoryId = 7000 + selectedPortId;

    const asyncCreateBinRequestFlow = async () => {
      const binIds: number[] = cycleCountsUncountedBins
        .map(
          (cycleCountUncountedBin) =>
            cycleCountUncountedBin.bin.autostoreBin?.autostoreBinId || 0
        )
        .filter((bId) => !!bId);

      dispatch(setPageState("close port"));
      // close workstation to reset task-group state before creating a new task-group
      await closeWorkstation();

      dispatch(setPortOpened(false));

      // create task group with cycle counts category
      await props.createBinRequest({ binIds, categoryId });

      // open port with same category as cycle-counts and start polling port
      dispatch(setPageState("open port"));

      await props.openPort({ categoryId }).then(() => {
        dispatch(setPortOpened(true));
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        props.fetchPortStatus();
        dispatch(setPageState("start polling"));
        if (!shouldListenToGridEvents) {
          dispatch(setPortPollingActive(true));
        } else {
          props.binNotAtPort();
        }
      });

      dispatch(setPageState("get next bin"));
      try {
        await props.getNextBinWithConfig();
      } catch {
        // todo: use toasts here
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    asyncCreateBinRequestFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleCountsUncountedBins]);

  // set bin configuration and compartment for the selected inventory
  useEffect(() => {
    setAutostoreBinConfiguration(nextBinConfiguration || null);

    setCompartment(
      matchingUncountedBin?.bin.autostoreCompartmentNumber
        ? matchingUncountedBin.bin.autostoreCompartmentNumber - 1
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextBinConfiguration]);

  // look for cycle counts with "ready to complete" status
  // defined in modifyShapedCycleCountInfo (cycleCounts reducer)
  useEffect(() => {
    if (!shapedCycleCountInfos.length) return;

    const cycleCountIdsReadyToComplete = shapedCycleCountInfos
      .filter((cycleCountInfo) => cycleCountInfo.status === "ready to complete")
      .map((cycleCountInfo) => cycleCountInfo.cycleCountId);

    if (cycleCountIdsReadyToComplete.length) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.completeCycleCounts(
        cycleCountIdsReadyToComplete,
        selectedAutostoreGridId
      );
    }

    setAllCycleCountsComplete(
      shapedCycleCountInfos.every(
        (cycleCountInfo) => cycleCountInfo.status === "complete"
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapedCycleCountInfos]);

  // after all bins are processed, open the confirmation modal
  // which will close and reset the page
  const showConfirmModal = allCycleCountsComplete || noMoreBins;
  useEffect(() => {
    if (showConfirmModal) {
      dispatch(setPageState("all cycle counts complete"));
      setIsConfirmModalOpen(true);
    }
  }, [dispatch, showConfirmModal]);

  const completedCycleCountList = shapedCycleCountInfos
    .filter((cycleCountInfo) => cycleCountInfo.status === "complete")
    .map((cycleCountInfo) => {
      const variant =
        cycleCountInfo.uncountedBinsInfo[0]?.uncountedBin?.variant ??
        incompleteCycleCounts.find(
          (incompleteCycleCount) =>
            incompleteCycleCount.cycleCountId === cycleCountInfo.cycleCountId
        )?.variant;

      return (
        getVariantDisplayNameByDtoFe(variant, usersFulfillmentCenter) ??
        cycleCountInfo.cycleCountId
      );
    });

  const gridSub = (data: AutostoreEvent) => {
    if (data.case !== "BinModeChange" || data.event.binMode !== "O") {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.fetchPortStatus().then((portStatus) => {
      if (
        data.event.binId === portStatus?.selectedBin &&
        data.event.gridId === props.selectedAutostoreGridId &&
        !!data.event.portId &&
        siteAllPortIds.includes(data.event.portId)
      ) {
        // Dispatch redux action
        props.binAtPortEvent();
        dispatch(setPageState("bin arrived"));
        dispatch(setBinPresent(true));
      }
    });
  };

  useGridV2Subscription(gridSub);

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid
        container
        sx={{ padding: "48px", height: "100%" }}
        spacing={3}
        direction="row"
      >
        {/* left side */}
        <Grid
          item
          rowSpacing={3}
          xs={12}
          md={10}
          lg={4}
          gap={2}
          container
          direction="column"
          style={{ margin: "16px auto 0" }}
        >
          <Grid item minHeight={"480px"}>
            {matchingUncountedBin ? (
              <UniversalProductCard
                productName={
                  getVariantDisplayNameByDtoFe(
                    matchingUncountedBin.variant,
                    usersFulfillmentCenter
                  ) ?? ""
                }
                imageFileName={matchingUncountedBin.variant.imageFilename}
                sku={matchingUncountedBin.variant.sku}
                weight={`${matchingUncountedBin.expectedCount?.value || ""} ${
                  matchingUncountedBin.expectedCount?.units || ""
                }`}
                upc={matchingUncountedBin.variant.sku}
              />
            ) : (
              <PickInfoIsLoading height={480} />
            )}
          </Grid>
          <Stack
            sx={{
              width: "100%",
              alignItems: "center"
            }}
          >
            {!portPollingActive && portState && portState.selectedBin ? (
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  width: "100%"
                }}
              >
                {t("bin")}{" "}
                {matchingNextBinInventory
                  ? matchingNextBinInventory.bin.autostoreBin?.autostoreBinId
                  : undefined}
              </Typography>
            ) : (
              ""
            )}
            <AutostoreBin
              state={
                !portOpened
                  ? "Port Closed"
                  : !binAtPort || portPollingActive
                    ? "Waiting for Bin"
                    : "Bin Opened"
              }
              pickQuantity={matchingNextBinInventory?.count?.value || ""}
              pickCompartment={compartment}
              numberOfColumns={
                autostoreBinConfiguration?.verticalCompartmentCount || 1
              }
              numberOfRows={
                autostoreBinConfiguration?.horizontalCompartmentCount || 1
              }
              binId={
                portOpened &&
                matchingNextBinInventory &&
                ((!portPollingActive && !shouldListenToGridEvents) ||
                  (binAtPort && shouldListenToGridEvents))
                  ? matchingNextBinInventory.bin.autostoreBin?.autostoreBinId
                  : undefined
              }
              hideBinId
            />
          </Stack>
        </Grid>

        {/* right side */}
        <Grid
          item
          xs={12}
          md={10}
          lg={8}
          container
          rowSpacing={3}
          direction="column"
          sx={{
            margin: "16px auto 0",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Grid item sx={{ height: "100%", width: "100%" }}>
            {/* open/new cycle count list */}
            {pageState === "initial" &&
              !!(
                matchingNextBinInventory ||
                loadingIncompleteCycleCounts ||
                incompleteCycleCounts.length
              ) && (
                <>
                  <AutostoreTable<IncompleteCycleCountDto>
                    headerColNames={[
                      t("product"),
                      t("sku"),
                      t("status"),
                      t("due by")
                    ]}
                    rowId={(row: IncompleteCycleCountDto) =>
                      row.productCycleId || row.cycleCountId || ""
                    }
                    renderColumns={[
                      (row: IncompleteCycleCountDto) =>
                        getDisplayNameByDto(
                          row.variant,
                          usersFulfillmentCenter
                        ) || "",
                      (row: IncompleteCycleCountDto) => row.variant.sku || "",
                      (row: IncompleteCycleCountDto) => row.status || "",
                      (row: IncompleteCycleCountDto) =>
                        formatUtcDate(row.cycleEndsOn)
                    ]}
                    widthOfCols={["40%", "20%", "20%", "20%"]}
                    headerVariant="overline"
                    bodyVariant="body2"
                    rowData={incompleteCycleCounts}
                    selectedRows={selectedRows}
                    selectRowCallback={(row: IncompleteCycleCountDto) => {
                      const rowId =
                        row.productCycleId || row.cycleCountId || "";
                      if (selectedRows.includes(rowId)) {
                        dispatch(
                          selectRows(selectedRows.filter((id) => id !== rowId))
                        );
                      } else {
                        dispatch(selectRows([...selectedRows, rowId]));
                      }
                    }}
                    loading={false}
                  />

                  <Grid justifyContent="center" sx={{ display: "flex", pt: 1 }}>
                    {totalPageCount > 1 && (
                      <Pagination
                        count={totalPageCount}
                        page={page}
                        onChange={(_e, p) => {
                          dispatch(setPage(p));
                          if (window.scrollTo) window.scrollTo(0, 0);
                        }}
                        shape="rounded"
                      />
                    )}
                  </Grid>
                </>
              )}
            {!matchingNextBinInventory &&
              !loadingIncompleteCycleCounts &&
              !incompleteCycleCounts.length && (
                <Grid>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ padding: 10 }}
                  >
                    {t("no cycle counts found")}
                  </Typography>
                </Grid>
              )}
            {/* bin info for current bin */}
            {matchingUncountedBin && (
              <AutostoreTable<UncountedBinDto>
                headerColNames={[
                  t("bin"),
                  t("quantity"),
                  t("holds"),
                  t(inventoryDateLabel(inv_inventoryDateLabel))
                ]}
                rowId={(row: UncountedBinDto) => row.inventoryId}
                renderColumns={[
                  (row) =>
                    row.bin.autostoreBin?.autostoreBinId.toString() ||
                    "no autostore bin",
                  (row: UncountedBinDto) =>
                    row.expectedCount?.value.toString() || "",
                  (row) => {
                    const inv = nextBinInventoryResponse.find(
                      (inventory) => inventory.inventoryId === row.inventoryId
                    );
                    if (inv?.holds.length) {
                      return (
                        <Typography
                          variant="body2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {inv.holds
                            .map((hold) => formatHoldText(hold))
                            .join(", ")}
                        </Typography>
                      );
                    }
                    return "";
                  },
                  (row) =>
                    checkIsExpiration(inv_inventoryDateLabel)
                      ? formatUtcDate(row.expiration)
                      : formatUtcDate(row.manufactureDate)
                ]}
                widthOfCols={["25%", "25%", "25%", "25%"]}
                headerVariant="overline"
                bodyVariant="body2"
                rowData={[matchingUncountedBin]}
                selectedRows={selectedBinRow ? [selectedBinRow] : undefined}
                selectRowCallback={(row: UncountedBinDto) => {
                  const rowId = row.inventoryId;
                  if (selectedBinRow === rowId) {
                    dispatch(clearSelectedBinRow());
                  } else {
                    dispatch(selectBinRow(rowId));
                  }
                }}
                loading={false}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* adjust inventory modal */}
      {invToModify && matchingUncountedBin && (
        <InventoryAdjustDialog
          open={isAdjustPanelOpen}
          onClose={() => dispatch(setIsAdjustPanelOpen(false))}
          invToModify={{
            ...invToModify,
            // use the uncounted bin expiration since it will be up to date if user adjusts date
            ...getInventoryDateObj(
              inv_inventoryDateLabel,
              moment(matchingUncountedBin?.expiration) ||
                moment(matchingUncountedBin?.manufactureDate)
            )
          }}
          selectedVariant={matchingUncountedBin.variant || null}
          refreshCb={({
            inventoryWasEmptied,
            newQuantity,
            inventoryId,
            newDate
          }) => {
            dispatch(setIsAdjustPanelOpen(false));
            if ((newQuantity || inventoryWasEmptied || newDate) && inventoryId)
              props.modifyUncountedBin({
                inventoryId,
                cycleCountId: matchingUncountedBin.cycleCountId,
                newQuantity,
                newDate: checkIsExpiration(inv_inventoryDateLabel)
                  ? { expirationDate: newDate }
                  : { manufactureDate: newDate }
              });
          }}
          canceledReason=""
        />
      )}

      {/* confirmation modal */}
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        noCancelButton
        confirmCb={() => {
          setIsConfirmModalOpen(false);
          resetPage();
        }}
        closeCb={() => {
          setIsConfirmModalOpen(false);
          resetPage();
        }}
        modalTitle={t("cycle count completed")}
        listToDisplay={completedCycleCountList}
      />
      <SelectorModal
        confirmCb={async (holdBin: HoldType) => {
          const { reasonCode } = holdBin;

          await props
            .placeInventoryHold(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              selectedInventory!.inventoryId,
              reasonCode,
              thisWorkstationId === undefined ? null : thisWorkstationId
            )
            .then(() => {
              dispatch(setIsBinHoldModalOpen(false));
            });
        }}
        open={isBinHoldModalOpen}
        closeModalCallback={() => dispatch(setIsBinHoldModalOpen(false))}
        maxWidth="sm"
        options={getHoldTypeOptions(excludeRecalledHold)}
        confirmButtonText={t("set hold")}
        currentHold=""
      />
    </Grid>
  );
}

export default connector(AutostoreCycleCounts);
