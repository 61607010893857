import { Pagination, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { ErrorPanel, TableInfoRow } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { BatchCards } from "~/components/batches/BatchCards";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGetBatchesQuery } from "~/redux/warehouse/batches.hooks";

import {
  resetSelectedBatch,
  selectBatch,
  selectHasFilters,
  setPage
} from "./pickBatches.slice";

export function PickBatchTable() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const limit = 10;

  const batchStatuses = useAppSelector(
    (state) => state.pickBatches.batchStatuses
  );
  const batchTypes = useAppSelector((state) => state.pickBatches.batchTypes);
  const orderType = useAppSelector((state) => state.pickBatches.orderType);
  const page = useAppSelector((state) => state.pickBatches.page);
  const search = useAppSelector((state) => state.pickBatches.search);
  const selectedBatch = useAppSelector(
    (state) => state.pickBatches.selectedBatch
  );
  const tempZones = useAppSelector((state) => state.pickBatches.tempZones);
  const toteId = useAppSelector((state) => state.pickBatches.toteId);

  const hasFilters = useAppSelector(selectHasFilters);

  const { data, isFetching, error } = useGetBatchesQuery(
    {
      limit,
      offset: (page - 1) * limit,
      status: batchStatuses,
      batchType: batchTypes,
      ...(search && { search }),
      // it would be nice to be able to search multiple temperature zones
      ...(tempZones.length && {
        temperatureZone: tempZones[0]
      }),
      ...(orderType && {
        orderType: orderType
      }),
      ...(toteId && { toteId })
    },
    { refetchOnMountOrArgChange: true }
  );

  const totalPageCount = data?.totalBatchCount
    ? Math.ceil(data?.totalBatchCount / limit)
    : 0;

  const noBatchesText = hasFilters
    ? t("no batches found try removing filters")
    : t("no batches in progress");

  return (
    <Box padding={2} overflow="hidden">
      {!!error && <ErrorPanel message={getMessageFromRtkError(error)} />}
      {data && data.batches.length === 0 && (
        <TableInfoRow textContent={noBatchesText} error={null} />
      )}
      {!error && data && data.batches.length > 0 && (
        <Stack>
          <BatchCards
            selectedBatch={selectedBatch}
            onBatchSelected={(clickedBatch) => {
              if (
                selectedBatch &&
                selectedBatch.batchId === clickedBatch.batchId
              ) {
                dispatch(resetSelectedBatch());
              } else {
                dispatch(selectBatch(clickedBatch));
              }
            }}
            batchesLoading={isFetching}
          />
          {totalPageCount > 1 && (
            <Pagination
              count={totalPageCount}
              page={page}
              onChange={(_e, p) => {
                setPage(p);
                setSearchParams(
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  {
                    ...searchParams,
                    page: p
                  } as never,
                  { replace: true }
                );
              }}
              shape="rounded"
            />
          )}
        </Stack>
      )}
    </Box>
  );
}
