import { DialogContent, DialogTitle, Grid } from "@mui/material";

import { ProgressButton, useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { CartSearchInput } from "~/features/cartSearchInput/CartSearchInput";

import {
  mixpanelTrack,
  mixpanelPickingConfirmCartWrapper,
  mixpanelTrackError
} from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { setCartNumberConfirmed } from "~/redux/actions";
import { selectBatchWithCartNumber } from "~/redux/selectors/batchSelectors";
import {
  selectSelectedPortId,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useClosePortMutation } from "~/redux/warehouse/autostoreGrid.hooks";

import { Root } from "./Root";

type CartNumberConfirmationModalProps = {
  maxWidth?: "xs" | "sm" | "md" | "lg";
  resetState: (reporter: string) => void;
};

export function CartNumberConfirmationModal(
  props: CartNumberConfirmationModalProps
) {
  const { maxWidth, resetState } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const batchWithCartNumber = useAppSelector(selectBatchWithCartNumber);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const portId = useAppSelector(selectSelectedPortId);

  const { successToast, errorToast } = useToast();
  const [closePort] = useClosePortMutation();
  const [preppedCartNumber, setPreppedCartNumber] = useState<string | null>(
    null
  );

  const onCancelCallback = async () => {
    try {
      if (autostoreGridId && portId) {
        await closePort({
          autostoreGridId,
          portId
        }).unwrap();
        successToast("Port closed.");
        navigate("/autostore-main");
      }
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  if (!batchWithCartNumber?.cartNumber) return <></>;

  return (
    <Root open maxWidth={maxWidth}>
      <DialogTitle>
        {t("bring cart")}
        {batchWithCartNumber?.cartNumber
          ? ` ${batchWithCartNumber?.cartNumber} `
          : " "}
        {t("to the port")}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item style={{ marginTop: 50 }}>
            <CartSearchInput
              setPreppedCartNumberCb={(cartNumber1) => {
                setPreppedCartNumber(cartNumber1);
              }}
              placeholder={t("scan cart to confirm")}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              style={{
                position: "relative",
                marginTop: 120
              }}
            >
              <Grid item style={{ position: "absolute", bottom: 0, right: 0 }}>
                <ProgressButton
                  data-testid="confirm-cart-number-button"
                  onClick={() => {
                    mixpanelPickingConfirmCartWrapper(
                      "Picking Confirm Cart",
                      "Button Click",
                      "Confirm",
                      {
                        cartNumber: batchWithCartNumber?.cartNumber || "",
                        preppedCartNumber
                      }
                    );
                    if (
                      preppedCartNumber &&
                      preppedCartNumber === batchWithCartNumber?.cartNumber
                    ) {
                      // Input number matches cart number
                      successToast(
                        `Successfully confirmed cart: ${batchWithCartNumber.cartNumber}`,
                        { duration: 1000 }
                      );
                      dispatch(setCartNumberConfirmed(true));
                    } else {
                      mixpanelTrackError(
                        "Picking Confirm Cart",
                        "Invalid cart number"
                      );
                      errorToast("Invalid cart number");
                    }
                  }}
                  buttonSize="medium"
                  emphasis="high"
                  responsive
                  variant="contained"
                  color="primary"
                  style={{ width: 130 }}
                >
                  {t("confirm")}
                </ProgressButton>
              </Grid>
              <Grid
                item
                style={{ position: "absolute", bottom: 0, right: 140 }}
              >
                <ProgressButton
                  data-testid="cancel-cart-number-button"
                  onClick={() => {
                    mixpanelTrack({
                      trackedPageName: "Picking Confirm Cart",
                      type: "Button Click",
                      label: "Cancel"
                    });
                    resetState("cart confirmation cancel");
                    void onCancelCallback();
                  }}
                  buttonSize="medium"
                  emphasis="high"
                  responsive
                  variant="contained"
                  color="primary"
                  style={{ width: 130 }}
                >
                  {t("cancel")}
                </ProgressButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Root>
  );
}
